html {
  overflow-x: hidden;
  height: 100%;
}
body {
  min-height: 100vh;
}
.tech-link {
  height: 100px;
  width: auto;
}
.robert-link {
  width: 80%;
  height: auto;
}
.image-link {
  margin-top: 160px;
}
.map-link {
  margin-top: 6rem;
}
.ref-link {
  height: 63px;
}
.portfolio-category > a {
  display: none;
}
.row.post-navigation.mt-5.justify-content-center {
  display: none;
}
.footer-space {
  margin-top: 100px;
}
.static-map {
  width: 100%;
  height: auto;
}
.visible {
  overflow: visible !important;
}
.modal-open {
  padding-right: 0px !important;

  .header {
    padding-right: 0px !important;
  }
}
.mb-50 {
  margin-bottom: 50px;
}
@media (max-width: 575px) {
  .quote-link {
    display: none;
  }
}
@media (max-width: 991px) {
  .contact-card {
    display: flex;
    align-items: center;
  }
}
